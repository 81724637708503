<template>
  <v-container style="margin-bottom: 100px">
    <customer-info logo="true" />

    <v-row justify="center">
      <v-col cols="12" sm="4" md="4" class="col-height">
        <v-btn
          color="primary"
          @click="$router.push({ name: 'TodayProject' })"
          elevation="2"
          large
          class="custom-btn-block"
        >
          Today's Project
        </v-btn>
      </v-col>
      <v-col cols="12" sm="4" md="4" class="col-height">
        <v-btn
          color="primary"
          @click="$router.push({ name: 'AllProjects' })"
          elevation="2"
          large
          class="custom-btn-block"
        >
          All Projects
        </v-btn>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" sm="4" md="4" class="col-height">
        <v-btn
          color="primary"
          @click="$router.push({ name: 'TimeClock' })"
          elevation="2"
          large
          class="custom-btn-block"
        >
          TimeClock
        </v-btn>
      </v-col>
      <v-col cols="12" sm="4" md="4" class="col-height">
        <v-btn
          color="primary"
          @click="$router.push({ name: 'Prep' })"
          elevation="2"
          large
          class="custom-btn-block"
        >
          Prep
        </v-btn>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" sm="4" md="4" class="col-height">
        <v-btn
          color="primary"
          elevation="2"
          large
          class="custom-btn-block"
          @click="$router.push({ name: 'ForemanFeedback' })"
        >
          Feedback
        </v-btn>
      </v-col>
      <v-col cols="12" sm="4" md="4" class="col-height">
        <v-btn
          color="primary"
          elevation="2"
          large
          class="custom-btn-block"
          @click="$router.push({ name: 'ForemanTraining' })"
        >
          Training
        </v-btn>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" sm="4" md="4" class="col-height">
        <v-btn
          color="primary"
          elevation="2"
          large
          class="custom-btn-block"
          @click="$router.push({ name: 'ForemanDirectory' })"
        >
          Directory
        </v-btn>
      </v-col>
      <v-col cols="12" sm="4" md="4" class="col-height">
        <v-btn
          color="primary"
          elevation="2"
          large
          class="custom-btn-block"
          @click="$router.push({ name: 'DriveShop' })"
        >
          Drive2Shop
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomerInfo from "./CustomerInfo";

export default {
  name: "ForemanMain",
  components: { CustomerInfo },
};
</script>

<style scoped>
.col-height {
  height: 115px;
}

.custom-btn-block {
  height: 100% !important;
  width: 100% !important;
}
</style>
